body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  padding: 0;
  /* Use a linear gradient with white color and opacity over the image */
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("../public/offices.jpg");
  background-size: cover;
  background-position: center;
  min-height: 100vh;   
}

/* CSS adjustments for styling the login page */
#welcomeDiv {
  display: flex;
  flexDirection: column;
  alignItems: center;
  justifyContent: center;
  position: relative;
  overflow: hidden;
}

#welcomeDiv::before {
  content: "";
  position: absolute;
  top: -20px;
  left: 0;
  width: 200%;
  height: 200%;
  background: inherit;
  background-attachment: fixed;
  -webkit-filter: blur(4px);
  filter: blur(4px);
  z-index: -1;
}

#welcomeDiv::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 0;
}

#welcomeDiv h2 {
  margin: 0;
  color: white;
  z-index: 1;
  padding: 20px 0; 
}
#signInDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
}