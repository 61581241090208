/* CalendarCustomStyles.css */

.rbc-overlay {
    max-height: 250px !important; /* Set a maximum height */
    overflow-y: auto !important; /* Enable vertical scrolling */
}
/*CSS adjustments to the Company Calendar for increased ledgibility*/
.rbc-month-view {
    background-color: white; 
  }
  .rbc-calendar {
    background: rgba(250, 250, 250, 0.9);
  }
  .rbc-toolbar-label{
      font-weight: bold;
  }

  /* CSS adjustment on the border radius and shadow of OfficeDays Datespicker to match the app design */
.css-vr95id-MuiPickersLayout-root {
    border-radius: 4px !important;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;
  }